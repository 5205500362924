<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <base-card>
          <template #header>خطأ 404</template>
          <template #body>
            <h6><span>هذه الصفحة غير موجودة</span>&nbsp;<router-link class="link" :to="{name: 'auctions.index'}">العودة للصفحة الرئيسية</router-link></h6>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";

export default {
  components: {BaseCard}
}
</script>
